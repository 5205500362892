@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
  text-align: left;
}

label {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
}

.table-container {
  width: 80%;
  margin: 0 auto;
}

.wrapper {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
}


.wrapper .contain {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 40px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 1px 60px #0000000f;
}

.wrapper .contain .table-container {
  width: 100%;
}

.titlbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.titlbar .backbtn {
  padding: 10px;
  background: blue;
  font-size: 24px;
  line-height: 1;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.titlbar h4 {
  margin: 0;
  font-size: 24px;
}

.wrapper .searchbar input[type="text"] {
  width: 100%;
  box-sizing: border-box;
  padding: 14px;
  height: auto;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.data-table th,
.data-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.data-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.data-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.data-table tbody tr:hover {
  background-color: #ddd;
}

/* RegistrationForm.css */

.link-container {
  text-align: center;
  margin-top: 20px;
}

.link-container a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.link-container a:hover {
  text-decoration: underline;
}

.back-btn {

  margin-top: 20px;
  margin-left: 20px
}

main {
  margin-top: 25px;
}

.form-control {
  font-size: 14px;
}

.navbar-expand .navbar-nav .nav-link{
  color: #fff;
}

@media only screen and (max-width: 500px) {
  .navbar-brand{
    display: none;
  }

  .navbar-expand .navbar-nav{
    margin-right: auto;
  }
}